import { EntityType } from '@type/config/entity.type';


export const gpaqDinamikaEntity: EntityType = {
  key: 'gpaq_dinamika',
  label: {
    singular: 'GPAQ динамика',
    plural: 'GPAQ динамика',
    genitive: 'GPAQ динамика',
  },
  type: 'one',
  parent: 'card',
  columns: [
    {
      label: 'Требует ли Ваша работа высокоинтенсивной деятельности?',
      key: 'trebuet-li-vasha-rabota-vysokointensivnoj',
      type: 'boolean',
      display: {
        list: true,
        link: false,
        create: true,
        update: true,
        one: true,
      },
      counted: true,
      prompt: 'Деятельность, при которой значительно учащается дыхание или пульс (например, поднятие тяжестей, земляные или строительные работы) непрерывно в течение, по крайней мере, 10 минут?'
    },
    {
      label: 'Сколько дней в неделю Вы обычно занимаетесь высокоинтенсивной деятельностью на работе?',
      key: 'skolko-dnej-vy-zanimaetes-vysokointensivnoj',
      type: 'number',
      display: {
        list: true,
        link: false,
        create: true,
        update: true,
        one: true,
      },
      counted: true,
      visibleRule: {
        fieldKey: 'trebuet-li-vasha-rabota-vysokointensivnoj',
        equal: true,
      },
    },
    {
      label: 'Сколько минут в день Вы обычно занимаетесь высокоинтенсивной деятельностью на работе?',
      key: 'skolko-minut-v-den-vy-zanimaetes-vysokointensivnoj',
      type: 'number',
      display: {
        list: true,
        link: false,
        create: true,
        update: true,
        one: true,
      },
      counted: true,
      visibleRule: {
        fieldKey: 'trebuet-li-vasha-rabota-vysokointensivnoj',
        equal: true,
      },
    },
    {
      label: 'Требует ли Ваша работа среднеинтенсивной деятельности?',
      key: 'trebuet-li-vasha-rabota-sredneintensivnoj-deyatelnosti',
      type: 'boolean',
      display: {
        list: true,
        link: false,
        create: true,
        update: true,
        one: true,
      },
      counted: true,
      prompt: 'Деятельность, при которой учащается дыхание или пульс (например, быстрая ходьба или поднятие небольших тяжестей) непрерывно в течение, по меньшей мере, 10 минут?',
    },
    {
      label: 'Сколько дней в неделю Вы обычно занимаетесь среднеинтенсивной деятельностью на работе?',
      key: 'skolko-dnej-vy-zanimaetes-sredneintensivnoj',
      type: 'number',
      display: {
        list: true,
        link: false,
        create: true,
        update: true,
        one: true,
      },
      counted: true,
      visibleRule: {
        fieldKey: 'trebuet-li-vasha-rabota-sredneintensivnoj-deyatelnosti',
        equal: true,
      },
    },
    {
      label: 'Сколько минут в день Вы обычно занимаетесь среднеинтенсивной деятельностью на работе?',
      key: 'skolko-minut-vy-zanimaetes-sredneintensivnoj',
      type: 'number',
      display: {
        list: true,
        link: false,
        create: true,
        update: true,
        one: true,
      },
      counted: true,
      visibleRule: {
        fieldKey: 'trebuet-li-vasha-rabota-sredneintensivnoj-deyatelnosti',
        equal: true,
      },
    },
    {
      label: 'Используете ли Вы ходьбу пешком или езду на велосипеде непрерывно в течение не менее 10 минут?',
      key: 'ispolzuete-li-vy-hodbu-peshkom',
      type: 'boolean',
      display: {
        list: true,
        link: false,
        create: true,
        update: true,
        one: true,
      },
      counted: true,
      prompt: 'Речь идет о средстве передвижения, а не о хобби или прогулках',
    },
    {
      label: 'Сколько дней в неделю Вы обычно ходите пешком или ездите на велосипеде непрерывно в течение не менее 10 минут?',
      key: 'skolko-dnej-v-nedelyu-hodite-peshkom',
      type: 'number',
      display: {
        list: true,
        link: false,
        create: true,
        update: true,
        one: true,
      },
      counted: true,
      visibleRule: {
        fieldKey: 'ispolzuete-li-vy-hodbu-peshkom',
        equal: true,
      },
    },
    {
      label: 'Сколько минут в день Вы обычно уделяете ходьбе пешком или езде на велосипеде в день?',
      key: 'skolko-minut-v-den-vy-udelyaete-hodbe',
      type: 'number',
      display: {
        list: true,
        link: false,
        create: true,
        update: true,
        one: true,
      },
      counted: true,
      visibleRule: {
        fieldKey: 'ispolzuete-li-vy-hodbu-peshkom',
        equal: true,
      },
    },
    {
      label: 'Носят ли Ваши занятия спортом, физическими упражнениями или активным досугом высокоинтенсивный характер?',
      key: 'zanyatiya-sportom-intensivnyj-har',
      type: 'boolean',
      display: {
        list: true,
        link: false,
        create: true,
        update: true,
        one: true,
      },
      counted: true,
      prompt: 'Физическая активность, при при которой значительно учащается дыхание или пульс (например, бег или футбол) непрерывно в течение, по меньшей мере, 10 минут?',
    },
    {
      label: 'Сколько дней в неделю Вы обычно занимаетесь спортом, физическими упражнениями или активным досугом высокоинтенсивного характера?',
      key: 'skolko-dnej-vy-zanimaetes-sportom',
      type: 'number',
      display: {
        list: true,
        link: false,
        create: true,
        update: true,
        one: true,
      },
      counted: true,
      visibleRule: {
        fieldKey: 'zanyatiya-sportom-intensivnyj-har',
        equal: true,
      },
    },
    {
      label: 'Сколько минут в день Вы обычно занимаетесь спортом, физическими упражнениями или активным досугом высокоинтенсивного характера?',
      key: 'skolko-minut-v-den-vy-zanimaetes-sportom',
      type: 'number',
      display: {
        list: true,
        link: false,
        create: true,
        update: true,
        one: true,
      },
      counted: true,
      visibleRule: {
        fieldKey: 'zanyatiya-sportom-intensivnyj-har',
        equal: true,
      },
    },
    {
      label: 'Носят ли Ваши занятия спортом, физическими упражнениями или активным досугом среднеинтенсивный характер?',
      key: 'zanyatiya-sportom-intensivnyj-sred',
      type: 'boolean',
      display: {
        list: true,
        link: false,
        create: true,
        update: true,
        one: true,
      },
      counted: true,
      prompt: 'Физическая активность, при которой немного учащается дыхание или пульс (например, быстрая ходьба, плавание, волейбол) непрерывно в течение, по меньшей мере, 10 минут?',
    },
    {
      label: 'Сколько дней в неделю Вы обычно занимаетесь спортом, физическими упражнениями или активным досугом среднеинтенсивного характера?',
      key: 'skolko-dnej-sredneintensivnogo',
      type: 'number',
      display: {
        list: true,
        link: false,
        create: true,
        update: true,
        one: true,
      },
      counted: true,
      visibleRule: {
        fieldKey: 'zanyatiya-sportom-intensivnyj-sred',
        equal: true,
      },
    },
    {
      label: 'Сколько минут в день Вы обычно занимаетесь спортом, физическими упражнениями или активным досугом среднеинтенсивного характера?',
      key: 'skolko-minut-sredneintensivnogo',
      type: 'number',
      display: {
        list: true,
        link: false,
        create: true,
        update: true,
        one: true,
      },
      counted: true,
      visibleRule: {
        fieldKey: 'zanyatiya-sportom-intensivnyj-sred',
        equal: true,
      },
    },
    {
      label: 'Сколько часов в день Вы обычно проводите сидя или полулежа?',
      key: 'skolko-chasov-provodite-sidya',
      type: 'decimal',
      display: {
        list: true,
        link: false,
        create: true,
        update: true,
        one: true,
      },
      counted: true,
      prompt: 'Учитывается учеба, деловые встречи, чтение, езда в автомобиле, отдых в положении лежа в течение дня и просмотр развлекательного контента',
    },
  ]
}
