import { EntityType } from '@type/config/entity.type';


export const ehkspressiyaP16PervichnayaEntity: EntityType = {
  key: 'ehkspressiya_p16_pervichnaya',
  label: {
    singular: 'Экспрессия P16 первичная',
    plural: 'Экспрессия P16 первичная',
    genitive: 'Экспрессия P16 первичная',
  },
  type: 'one',
  parent: 'card',
  columns: [
    {
      label: 'Уровень экспрессии P16',
      key: 'uroven-ehkspressii-p16',
      type: 'decimal',
      display: {
        list: true,
        link: false,
        create: true,
        update: true,
        one: true,
      },
      counted: true,
    },
    {
      label: 'Комментарий',
      key: 'kommentarij',
      type: 'long-text',
      display: {
        list: true,
        link: false,
        create: true,
        update: true,
        one: true,
      },
    },
  ]
}
