import { EntityType } from '@type/config/entity.type';


export const vitaminDDinamikaEntity: EntityType = {
  key: 'vitamin_d_dinamika',
  label: {
    singular: 'Витамин D динамика',
    plural: 'Витамин D динамика',
    genitive: 'Витамин D динамика',
  },
  type: 'one',
  parent: 'card',
  columns: [
    {
      label: 'Витамин D 25(OH)D крови, нг/мл',
      key: 'vitamin-d-25-oh-d-krovi-ng-ml',
      type: 'decimal',
      display: {
        list: true,
        link: false,
        create: true,
        update: true,
        one: true,
      },
      counted: true,
    },
    {
      label: 'Комментарий',
      key: 'kommentarij',
      type: 'long-text',
      display: {
        list: true,
        link: false,
        create: true,
        update: true,
        one: true,
      },
    },
  ]
}
