import { SyntheticEvent, useCallback, useState } from 'react';

import { ButtonCancelBlock } from '@blocks/button-cancel';
import { ButtonSaveBlock } from '@blocks/button-save';
import { FieldBooleanBlock } from '@blocks/field-boolean';
import { FieldDateBlock } from '@blocks/field-date';
import { FieldDatetimeBlock } from '@blocks/field-datetime';
import { FieldMultiselectBlock } from '@blocks/field-multiselect';
import { FieldNumberBlock } from '@blocks/field-number';
import { FieldDecimalBlock } from '@blocks/field-decimal';
import { FieldPhoneBlock } from '@blocks/field-phone';
import { FieldSelectBlock } from '@blocks/field-select';
import { FieldTextBlock } from '@blocks/field-text';
import { FieldLongTextBlock } from '@blocks/field-long-text';
import { FieldSnilsBlock } from '@blocks/field-snils';
import { checkVisibleField } from '@helpers/check-visible-field.helper';
import { errorTranslate } from '@helpers/error-translate.helper';
import { ChangeFieldEventType } from '@type/shared/change-field-event.type';

import styles from './entity-form.module.scss';
import { EntityFormProps } from './entity-form.props';
import { FieldPassportBlock } from '@blocks/field-passport';


const EntityFormComponent = ({ entity, onSubmitHandler, collection, onCancelHandler, submitting }: EntityFormProps) => {
  const isUpdate = !!collection.current;
  const buildInitialFormValue = useCallback(
    () => {
      const initialFormValue: { [key: string]: any } = {};

      if (collection.current) {
        entity.columns.forEach((column) => {
          if (column.display.update) {
            if ((column.type === 'select') && collection.current![column.key] === null) {
              initialFormValue[column.key] = '';
            } else if ((column.type === 'decimal' || column.type === 'number') && collection.current![column.key] === null) {
              initialFormValue[column.key] = '';
            } else if (collection.current![column.key] === null) {
              initialFormValue[column.key] = '';
            } else {
              initialFormValue[column.key] = collection.current![column.key];
            }
          }
        });
      } else {
        entity.columns.forEach((column) => {
          if (isUpdate ? column.display.update : column.display.create) {
            if (column.type === 'boolean' || column.type === 'date' || column.type === 'datetime') {
              initialFormValue[column.key] = null;
            } else if (column.type === 'multiselect') {
              initialFormValue[column.key] = [];
            } else {
              initialFormValue[column.key] = '';
            }
          }
        });
      }

      return initialFormValue;
    },
    [collection, entity.columns, isUpdate],
  );

  const [formValue, setFormValue] = useState(buildInitialFormValue());
  const [formDirty, setFormDirty] = useState(false);

  const onSubmitForm = (e?: SyntheticEvent) => {
    if (e) e.preventDefault();
    onSubmitHandler(formValue);
  }

  const changeFormValue = (e: ChangeFieldEventType | null): void => {
    setFormDirty(true);
    setFormValue((state) => {
      if (!e) return state;

      return {
        ...state,
        [e!.target.name]: e!.target.value,
      }
    });
  };

  const setError = (columnKey: string) => {
    return collection.errors && collection.errors[columnKey] && errorTranslate(collection.errors[columnKey][0]);
  }

  return (
    <div className="row justify-content-lg-center">
      <div className="col col-lg-6">
        <div className={styles['update-form']}>
          <form onSubmit={(e: SyntheticEvent) => onSubmitForm(e)}>
            <>
              {entity.columns.map((column) => {
                const invisibleField = !(
                  (isUpdate ? column.display.update : column.display.create) 
                  && checkVisibleField(column, formValue)
                );

                if (invisibleField) {
                  return null;
                }

                if (column.type === 'boolean') {
                  return (
                    <FieldBooleanBlock
                      key={column.key}
                      name={column.key}
                      label={column.label}
                      value={formValue[column.key]}
                      onChange={changeFormValue}
                      error={setError(column.key)}
                      counted={column.counted}
                      required={column.required}
                      prompt={column.prompt}
                    />
                  );
                } else if (column.type === 'date') {
                  return (
                    <FieldDateBlock
                      key={column.key}
                      name={column.key}
                      label={column.label}
                      value={formValue[column.key]}
                      onChange={changeFormValue}
                      error={setError(column.key)}
                      counted={column.counted}
                      required={column.required}
                      prompt={column.prompt}
                    />
                  );
                } else if (column.type === 'datetime') {
                  return (
                    <FieldDatetimeBlock
                      key={column.key}
                      name={column.key}
                      label={column.label}
                      value={formValue[column.key]}
                      onChange={changeFormValue}
                      error={setError(column.key)}
                      counted={column.counted}
                      required={column.required}
                      prompt={column.prompt}
                    />
                  );
                } else if (column.type === 'multiselect') {
                  return (
                    <FieldMultiselectBlock
                      key={column.key}
                      name={column.key}
                      label={column.label}
                      value={formValue[column.key]}
                      onChange={changeFormValue}
                      error={setError(column.key)}
                      items={column.variants!.map((item) => ({ value: item, label: item }))}
                      counted={column.counted}
                      required={column.required}
                      prompt={column.prompt}
                    />
                  );
                } else if (column.type === 'select') {
                  return (
                    <FieldSelectBlock
                      key={column.key}
                      name={column.key}
                      label={column.label}
                      value={formValue[column.key]}
                      onChange={changeFormValue}
                      error={setError(column.key)}
                      items={column.variants!.map((item) => ({ value: item, label: item }))}
                      counted={column.counted}
                      required={column.required}
                      prompt={column.prompt}
                    />
                  );
                } else if (column.type === 'number') {
                  return (
                    <FieldNumberBlock
                      key={column.key}
                      name={column.key}
                      label={column.label}
                      value={formValue[column.key]}
                      onChange={changeFormValue}
                      error={setError(column.key)}
                      counted={column.counted}
                      required={column.required}
                      prompt={column.prompt}
                    />
                  );
                } else if (column.type === 'decimal') {
                  return (
                    <FieldDecimalBlock
                      key={column.key}
                      name={column.key}
                      label={column.label}
                      value={formValue[column.key]}
                      onChange={changeFormValue}
                      error={setError(column.key)}
                      counted={column.counted}
                      required={column.required}
                      prompt={column.prompt}
                    />
                  );
                } else if (column.type === 'phone') {
                  return (
                    <FieldPhoneBlock
                      key={column.key}
                      name={column.key}
                      label={column.label}
                      value={formValue[column.key]}
                      onChange={changeFormValue}
                      error={setError(column.key)}
                      counted={column.counted}
                      required={column.required}
                      prompt={column.prompt}
                    />
                  );
                } else if (column.type === 'long-text') {
                  return (
                    <FieldLongTextBlock
                      key={column.key}
                      name={column.key}
                      label={column.label}
                      value={formValue[column.key]}
                      onChange={changeFormValue}
                      error={setError(column.key)}
                      counted={column.counted}
                      required={column.required}
                      prompt={column.prompt}
                    />
                  );
                } else if (column.type === 'snils') {
                  return (
                    <FieldSnilsBlock
                      key={column.key}
                      name={column.key}
                      label={column.label}
                      value={formValue[column.key]}
                      onChange={changeFormValue}
                      error={setError(column.key)}
                      counted={column.counted}
                      required={column.required}
                      prompt={column.prompt}
                    />
                  );
                } else if (column.type === 'passport') {
                  return (
                    <FieldPassportBlock
                      key={column.key}
                      name={column.key}
                      label={column.label}
                      value={formValue[column.key]}
                      onChange={changeFormValue}
                      error={setError(column.key)}
                      counted={column.counted}
                      required={column.required}
                      prompt={column.prompt}
                    />
                  );
                } else {
                  return (
                    <FieldTextBlock
                      key={column.key}
                      name={column.key}
                      label={column.label}
                      value={formValue[column.key]}
                      onChange={changeFormValue}
                      error={setError(column.key)}
                      counted={column.counted}
                      required={column.required}
                      prompt={column.prompt}
                    />
                  );
                }
              })}

              <ButtonSaveBlock
                loading={submitting}
                onClick={onSubmitForm}
                disabled={!formDirty}
              />
              <ButtonCancelBlock onClick={onCancelHandler}/>
            </>
          </form>
        </div>
      </div>
    </div>
 );
} 

export { EntityFormComponent };
