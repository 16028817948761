import { createTheme } from '@mui/material/styles';
import { red } from '@mui/material/colors';

export const isDev = !!process.env.REACT_APP_IS_DEV;
export const apiUrl = isDev ? 'http://localhost:3000' : 'https://adapt-api.agdrv.ru';

export const theme = createTheme({
  typography: {
    h1: {
      fontWeight: 500,
    }
  },
  palette: {
    // mode: 'dark',
    primary: {
      main: '#556cd6',
    },
    secondary: {
      main: '#19857b',
    },
    error: {
      main: red.A400,
    },
  },
});
