import { EntityType } from '@type/config/entity.type';


export const hadsPervichnyjEntity: EntityType = {
  key: 'hads_pervichnyj',
  label: {
    singular: 'HADS первичный',
    plural: 'HADS первичный',
    genitive: 'HADS первичный',
  },
  type: 'one',
  parent: 'card',
  columns: [
    {
      label: 'Дата первичного опроса',
      key: 'data-pervichnogo-oprosa',
      type: 'date',
      display: {
        list: true,
        link: false,
        create: true,
        update: true,
        one: true,
      },
      required: true,
    },
    {
      label: 'Я испытываю напряжение, мне не по себе',
      key: 'ya-ispytyvayu-napryazhenie-mne-ne-po-sebe',
      type: 'select',
      display: {
        list: true,
        link: false,
        create: true,
        update: true,
        one: true,
      },
      variants: [
        '3 - Все время',
        '2 - Часто',
        '1 - Время от времени, иногда',
        '0 - Совсем не испытываю',
      ],
      counted: true,
    },
    {
      label: 'Я испытываю страх, кажется, что что-то ужасное может вот-вот случиться',
      key: 'ya-ispytyvayu-strah',
      type: 'select',
      display: {
        list: true,
        link: false,
        create: true,
        update: true,
        one: true,
      },
      variants: [
        '3 - Определенно, это так, и страх очень велик',
        '2 - Да, это так, но страх не очень велик',
        '1 - Иногда, но это меня не беспокоит',
        '0 - Совсем не испытываю',
      ],
      counted: true,
    },
    {
      label: 'Беспокойные мысли крутятся у меня в голове',
      key: 'bespokojnye-mysli-krutyatsya-u-menya-v-golove',
      type: 'select',
      display: {
        list: true,
        link: false,
        create: true,
        update: true,
        one: true,
      },
      variants: [
        '3 - Постоянно',
        '2 - Большую часть времени',
        '1 - Время от времени и не так часто',
        '0 - Только иногда',
      ],
      counted: true,
    },
    {
      label: 'Я легко могу присесть и расслабиться',
      key: 'ya-legko-mogu-prisest-i-rasslabitsya',
      type: 'select',
      display: {
        list: true,
        link: false,
        create: true,
        update: true,
        one: true,
      },
      variants: [
        '0 - Определенно, это так',
        '1 - Наверное, это так',
        '2 - Лишь изредка, это так',
        '3 - Совсем не могу',
      ],
      counted: true,
    },
    {
      label: 'Я испытываю внутренне напряжение или дрожь',
      key: 'ya-ispytyvayu-vnutrenne-napryazhenie',
      type: 'select',
      display: {
        list: true,
        link: false,
        create: true,
        update: true,
        one: true,
      },
      variants: [
        '0 - Совсем не испытываю',
        '1 - Иногда',
        '2 - Часто',
        '3 - Очень часто',
      ],
      counted: true,
    },
    {
      label: 'Я испытываю неусидчивость, мне постоянно нужно двигаться',
      key: 'ya-ispytyvayu-neusidchivost',
      type: 'select',
      display: {
        list: true,
        link: false,
        create: true,
        update: true,
        one: true,
      },
      variants: [
        '3 - Определенно, это так',
        '2 - Наверное, это так',
        '1 - Лишь в некоторой степени, это так',
        '0 - Совсем не испытываю',
      ],
      counted: true,
    },
    {
      label: 'У меня бывает внезапное чувство паники',
      key: 'u-menya-byvaet-vnezapnoe-chuvstvo-paniki',
      type: 'select',
      display: {
        list: true,
        link: false,
        create: true,
        update: true,
        one: true,
      },
      variants: [
        '3 - Очень часто',
        '2 - Довольно часто',
        '1 - Не так уж часто',
        '0 - Совсем не бывает',
      ],
      counted: true,
    },
    {
      label: 'То, что приносило мне большое удовольствие, и сейчас вызывает у меня такое же чувство',
      key: 'to-chto-prinosilo-mne-bolshoe-udovolstvie',
      type: 'select',
      display: {
        list: true,
        link: false,
        create: true,
        update: true,
        one: true,
      },
      variants: [
        '0 - Определенно, это так',
        '1 - Наверное, это так',
        '2 - Лишь в некоторой степени, это так',
        '3 - Это совсем не так',
      ],
      counted: true,
    },
    {
      label: 'Я способен рассмеяться и увидеть в том или ином событии смешное',
      key: 'ya-sposoben-rassmeyatsya',
      type: 'select',
      display: {
        list: true,
        link: false,
        create: true,
        update: true,
        one: true,
      },
      variants: [
        '0 - Определенно, это так',
        '1 - Наверное, это так',
        '2 - Лишь в некоторой степени, это так',
        '3 - Совсем не способен',
      ],
      counted: true,
    },
    {
      label: 'Я испытываю бодрость',
      key: 'ya-ispytyvayu-bodrost',
      type: 'select',
      display: {
        list: true,
        link: false,
        create: true,
        update: true,
        one: true,
      },
      variants: [
        '3 - Совсем не испытываю',
        '2 - Очень редко',
        '1 - Иногда',
        '0 - Практически все время',
      ],
      counted: true,
    },
    {
      label: 'Мне кажется, что я стал все делать очень медленно',
      key: 'mne-kazhetsya-chto-ya-stal-vse-delat-ochen-medlenno',
      type: 'select',
      display: {
        list: true,
        link: false,
        create: true,
        update: true,
        one: true,
      },
      variants: [
        '3 - Практически все время',
        '2 - Часто',
        '1 - Иногда',
        '0 - Совсем нет',
      ],
      counted: true,
    },
    {
      label: 'Я не слежу за своей внешностью',
      key: 'ya-ne-slezhu-za-svoej-vneshnostyu',
      type: 'select',
      display: {
        list: true,
        link: false,
        create: true,
        update: true,
        one: true,
      },
      variants: [
        '3 - Определенно, это так',
        '2 - Я не уделяю этому столько времени, сколько нужно',
        '1 - Может быть, я стал меньше уделять этому времени',
        '0 - Я слежу за собой так же, как и раньше',
      ],
      counted: true,
    },
    {
      label: 'Я считаю, что мои дела (занятия, увлечения) могут принести мне чувство удовлетворения',
      key: 'ya-schitayu-moi-dela-mogut-mne-udovl',
      type: 'select',
      display: {
        list: true,
        link: false,
        create: true,
        update: true,
        one: true,
      },
      variants: [
        '0 - Точно так же, как и обычно',
        '1 - Да, но не в той степени, как раньше',
        '2 - Значительно меньше, чем обычно',
        '3 - Совсем так не считаю',
      ],
      counted: true,
    },
    {
      label: 'Я могу получить удовольствие от хорошей книги, радио или телепрограммы',
      key: 'ya-mogu-poluchit-udovl-ot-horoshej-knigi',
      type: 'select',
      display: {
        list: true,
        link: false,
        create: true,
        update: true,
        one: true,
      },
      variants: [
        '0 - Часто',
        '1 - Иногда',
        '2 - Редко',
        '3 - Очень редко',
      ],
      counted: true,
    },
    {
      label: 'Сумма баллов тревоги',
      key: 'summa-ballov-trevogi',
      type: 'number',
      display: {
        list: true,
        link: false,
        create: true,
        update: true,
        one: true,
      },
      counted: true,
    },
    {
      label: 'Сумма баллов депрессии',
      key: 'summa-ballov-depressii',
      type: 'number',
      display: {
        list: true,
        link: false,
        create: true,
        update: true,
        one: true,
      },
      counted: true,
    },
  ]
}
