import { EntityType } from '@type/config/entity.type';
import { spisokJalobDict } from './dict/spisok-jalob.dict';


export const pitanieIObrazZhizniPervichnyjEntity: EntityType = {
  key: 'pitanie_i_obraz_zhizni_pervichnyj',
  label: {
    singular: 'Питание и образ жизни первичный',
    plural: 'Питание и образ жизни первичный',
    genitive: 'Питание и образ жизни первичный',
  },
  type: 'one',
  parent: 'card',
  columns: [
    {
      label: 'Список жалоб',
      key: 'spisok-zhalob',
      type: 'multiselect',
      display: {
        list: true,
        link: false,
        create: true,
        update: true,
        one: true,
      },
      counted: true,
      variants: spisokJalobDict,
    },
    {
      label: 'Рост, см',
      key: 'rost-sm',
      type: 'number',
      display: {
        list: true,
        link: false,
        create: true,
        update: true,
        one: true,
      },
      counted: true,
    },
    {
      label: 'Вес, кг',
      key: 'ves-kg',
      type: 'number',
      display: {
        list: true,
        link: false,
        create: true,
        update: true,
        one: true,
      },
      counted: true,
    },
    {
      label: 'Отношение к курению (в том числе электронные испарители)',
      key: 'otnoshenie-k-kureniyu',
      type: 'select',
      display: {
        list: true,
        link: false,
        create: true,
        update: true,
        one: true,
      },
      counted: true,
      variants: [
        'Не курю',
        'Курю',
      ]
    },
    {
      label: 'Преобладающий тип курения',
      key: 'preobladayushchij-tip-kureniya',
      type: 'select',
      display: {
        list: true,
        link: false,
        create: true,
        update: true,
        one: true,
      },
      counted: true,
      variants: [
        'Сигареты',
        'Системы электронной доставки никотина',
        'Вейп',
        'Электронные системы нагревания табака (IQOS)',
        'Кальян',
        'Другое',
      ],
      visibleRule: {
        fieldKey: 'otnoshenie-k-kureniyu',
        oneOf: [
          'Курю',
        ]
      }
    },
    {
      label: 'Число сигарет/стиков в день',
      key: 'chislo-sigaret-stikov-v-den',
      type: 'number',
      display: {
        list: true,
        link: false,
        create: true,
        update: true,
        one: true,
      },
      counted: true,
      visibleRule: {
        fieldKey: 'otnoshenie-k-kureniyu',
        oneOf: [
          'Курю',
        ]
      }
    },
    {
      label: 'Стаж курения (лет)',
      key: 'stazh-kureniya-let',
      type: 'select',
      display: {
        list: true,
        link: false,
        create: true,
        update: true,
        one: true,
      },
      counted: true,
      variants: [
        'Менее года',
        '1-2 года',
        '3-4 года',
        '5 лет и более',
      ],
      visibleRule: {
        fieldKey: 'otnoshenie-k-kureniyu',
        oneOf: [
          'Курю',
        ]
      }
    },
    {
      label: 'Отношение к алкоголю',
      key: 'otnoshenie-k-alkogolyu',
      type: 'select',
      display: {
        list: true,
        link: false,
        create: true,
        update: true,
        one: true,
      },
      counted: true,
      variants: [
        'Не употребляю',
        'Употребляю'
      ],
    },
    {
      label: 'Какой алкоголь вы предпочитаете?',
      key: 'kakoj-alkogol-vy-predpochitaete',
      type: 'multiselect',
      display: {
        list: true,
        link: false,
        create: true,
        update: true,
        one: true,
      },
      counted: true,
      variants: [
        'Пиво',
        'Вино',
        'Крепкий алкоголь',
      ],
      visibleRule: {
        fieldKey: 'otnoshenie-k-alkogolyu',
        oneOf: [
          'Употребляю',
        ]
      }
    },
    {
      label: 'Сколько стандартных доз алкоголя в неделю вы употребляете (25гр. 40% водки, 100 гр. 9–11% сухого вина или 200 гр. 3–5% пива))',
      key: 'skolko-standartnyh-doz',
      type: 'number',
      display: {
        list: true,
        link: false,
        create: true,
        update: true,
        one: true,
      },
      counted: true,
      visibleRule: {
        fieldKey: 'otnoshenie-k-alkogolyu',
        oneOf: [
          'Употребляю',
        ]
      }
    },
    {
      label: 'Принимали ли Вы антибактериальные препараты (антибиотики) в течение последних 30 дней?',
      key: 'prinimali-li-vy-antibakterialnye-preparaty',
      type: 'select',
      display: {
        list: true,
        link: false,
        create: true,
        update: true,
        one: true,
      },
      counted: true,
      variants: [
        'Не принимал/-а',
        'Принимал/-а',
        'Принимаю сейчас'
      ],
    },
    // {
    //   label: 'Тип питания',
    //   key: 'tip-pitaniya',
    //   type: 'select',
    //   display: {
    //     list: true,
    //     link: false,
    //     create: true,
    //     update: true,
    //     one: true,
    //   },
    //   counted: true,
    //   variants: [
    //     'Всеядение',
    //     'Вегетарианство',
    //     'Веганство',
    //     '"Правильное питание" (подсчет калорий и учет нутриентов)',
    //     'Лечебно-диетическое питание',
    //   ],
    // },
    {
      label: 'Как часто вы употребляете пищу в течение дня? (без перекусов)',
      key: 'kak-chasto-vy-upotreblyaete-pishchu',
      type: 'select',
      display: {
        list: true,
        link: false,
        create: true,
        update: true,
        one: true,
      },
      counted: true,
      variants: [
        '1-2 раза в день',
        '3 раза в день',
        '4-5 раз в день',
        '6 раз и более',
      ],
    },
    {
      label: 'Как часто вы перекусываете в течение дня?',
      key: 'kak-chasto-vy-perekusyvaete',
      type: 'select',
      display: {
        list: true,
        link: false,
        create: true,
        update: true,
        one: true,
      },
      counted: true,
      variants: [
        'Никогда',
        '1 раз в день',
        '2-3 раза в день',
        'Более 3 раз в день',
      ],
    },
    {
      label: 'Сколько минут в среднем у Вас уходит на 1 приём пищи?',
      key: 'skolko-minut-na-1-priyom-pishchi',
      type: 'select',
      display: {
        list: true,
        link: false,
        create: true,
        update: true,
        one: true,
      },
      counted: true,
      variants: [
        'Менее 10 минут',
        '10-15 минут',
        '15-30 минут',
        'Более 30 минут',
      ],
    },
    {
      label: 'Часто ли вы употребляете фастфуд (гамбургеры, шаурма и т.д.)?',
      key: 'chasto-li-vy-upotreblyaete-fastfud',
      type: 'select',
      display: {
        list: true,
        link: false,
        create: true,
        update: true,
        one: true,
      },
      counted: true,
      variants: [
        'Не употребляю',
        '1 раз в месяц и реже',
        '2-3 раза в месяц',
        '1-2 раза в неделю',
        'Каждый день',
      ],
    },
    {
      label: 'Часто ли вы употребляете мучные кулинарные изделия (булочки, пирожки, чебуреки и др.):',
      key: 'chasto-li-vy-upotreblyaete-muchnye',
      type: 'select',
      display: {
        list: true,
        link: false,
        create: true,
        update: true,
        one: true,
      },
      counted: true,
      variants: [
        'Не употребляю',
        '1 раз в месяц и реже',
        '2-3 раза в месяц',
        '1-2 раза в неделю',
        'Каждый день',
      ],
    },
    {
      label: 'Часто ли вы употребляете продукты, проходящие обжарку на растительном масле (жареные продукты)?',
      key: 'chasto-li-vy-upotreblyaete-obzharku',
      type: 'select',
      display: {
        list: true,
        link: false,
        create: true,
        update: true,
        one: true,
      },
      counted: true,
      variants: [
        'Не употребляю',
        '1 раз в месяц и реже',
        '2-3 раза в месяц',
        '1-2 раза в неделю',
        'Каждый день',
      ],
    },
    {
      label: 'Как часто вы употребляете продукты с повышенным содержанием соли (соленые орешки, сухарики)?',
      key: 'kak-chasto-vy-upotreblyaete-soli',
      type: 'select',
      display: {
        list: true,
        link: false,
        create: true,
        update: true,
        one: true,
      },
      counted: true,
      variants: [
        'Не употребляю',
        '1 раз в месяц и реже',
        '2-3 раза в месяц',
        '1-2 раза в неделю',
        'Каждый день',
      ],
    },
    {
      label: 'Как часто вы употребляете продукты с повышенным содержанием сахара (пирожные, торт, шоколад и др.)?',
      key: 'kak-chasto-vy-upotreblyaete-sahara',
      type: 'select',
      display: {
        list: true,
        link: false,
        create: true,
        update: true,
        one: true,
      },
      counted: true,
      variants: [
        'Не употребляю',
        '1 раз в месяц и реже',
        '2-3 раза в месяц',
        '1-2 раза в неделю',
        'Каждый день',
      ],
    },
    {
      label: 'Где вы обычно питаетесь в учебное время?',
      key: 'gde-vy-obychno-pitaetes-v-uchebnoe-vremya',
      type: 'select',
      display: {
        list: true,
        link: false,
        create: true,
        update: true,
        one: true,
      },
      counted: true,
      variants: [
        'Приношу пищу с собой',
        'В столовой',
        'Обедаю дома',
      ],
    },
    {
      label: 'Занимаетесь ли Вы спортом?',
      key: 'zanimaetes-li-vy-sportom',
      type: 'select',
      display: {
        list: true,
        link: false,
        create: true,
        update: true,
        one: true,
      },
      counted: true,
      variants: [
        'Активно занимаюсь спортом',
        'Не занимаюсь спортом',
        'Временно не занимаюсь спортом (по состоянию здоровья)',
      ],
    },
    {
      label: 'Как изменился ваш вес за последние полгода?',
      key: 'kak-izmenilsya-vash-ves-za-poslednie-polgoda',
      type: 'select',
      display: {
        list: true,
        link: false,
        create: true,
        update: true,
        one: true,
      },
      counted: true,
      variants: [
        'Вес не изменился',
        'Набрал(а) вес',
        'Похудел(а)',

      ],
    },
  ]
}
