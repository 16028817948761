import { ConfigType } from '@type/config/config.type';

import { cardEntity } from './card.entity';
import { ehkspressiyaP16DinamikaEntity } from './ehkspressiya-p16-dinamika.entity';
import { ehkspressiyaP16PervichnayaEntity } from './ehkspressiya-p16-pervichnaya.entity';
import { gpaqDinamikaEntity } from './gpaq-dinamika.entity';
import { gpaqPervichnyjEntity } from './gpaq-pervichnyj.entity';
import { hadsDinamikaEntity } from './hads-dinamika.entity';
import { hadsPervichnyjEntity } from './hads-pervichnyj.entity';
import { laboratornyePokazateliDinamikaEntity } from './laboratornye-pokazateli-dinamika.entity';
import { laboratornyePokazateliPervichnyeEntity } from './laboratornye-pokazateli-pervichnye.entity';
import { pitanieIObrazZhizniDinamikaEntity } from './pitanie-i-obraz-zhizni-dinamika.entity';
import { pitanieIObrazZhizniPervichnyjEntity } from './pitanie-i-obraz-zhizni-pervichnyj.entity';
import { vitaminDDinamikaEntity } from './vitamin-d-dinamika.entity';
import { vitaminDPervichnyjEntity } from './vitamin-d-pervichnyj.entity';


export const configDcs: ConfigType = {
  appTitle: 'ADAPT',
  entities: [
    cardEntity,
    hadsPervichnyjEntity,
    gpaqPervichnyjEntity,
    pitanieIObrazZhizniPervichnyjEntity,
    laboratornyePokazateliPervichnyeEntity,
    vitaminDPervichnyjEntity,
    ehkspressiyaP16PervichnayaEntity,

    hadsDinamikaEntity,
    gpaqDinamikaEntity,
    pitanieIObrazZhizniDinamikaEntity,
    laboratornyePokazateliDinamikaEntity,
    vitaminDDinamikaEntity,
    ehkspressiyaP16DinamikaEntity,
  ],
};
