import { SyntheticEvent, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { createCollectionItemAction } from '@stores/collection/collection.actions';
import { useAppDispatch, useAppSelector } from '@hooks/redux.hooks';
import { ChangeFieldEventType } from '@type/shared/change-field-event.type';

import { ButtonBackBlock } from '@blocks/button-back';
import { CardDataBlock } from '@blocks/card-data';
import { FieldTextBlock } from '@blocks/field-text';
import { ButtonSaveBlock } from '@blocks/button-save';
import { FieldSelectBlock } from '@blocks/field-select';
import { FieldBooleanBlock } from '@blocks/field-boolean';
import { FieldDateBlock } from '@blocks/field-date';
import { FieldMultiselectBlock } from '@blocks/field-multiselect';
import { FieldNumberBlock } from '@blocks/field-number';
import { FieldDecimalBlock } from '@blocks/field-decimal';
import { FieldPhoneBlock } from '@blocks/field-phone';
import { FieldDatetimeBlock } from '@blocks/field-datetime';
import { FieldLongTextBlock } from '@blocks/field-long-text';
import { checkVisibleField } from '@helpers/check-visible-field.helper';
import { errorTranslate } from '@helpers/error-translate.helper';
import { prepareFormValue } from '@helpers/prepare-form-value.helper';
import { CollectionItemType } from '@type/collection/collection-item.type';

import { EntityCreateProps } from './entity-create.props';
import styles from './entity-create.module.scss';
import { FieldSnilsBlock } from '@blocks/field-snils';
import { FieldPassportBlock } from '@blocks/field-passport';


const EntityCreateFeature = ({ entity, parentId }: EntityCreateProps) => {
  const initialFormValue: { [key: string]: any } = {};

  entity.columns.forEach((column) => {
    if (column.display.create) {
      if (column.type === 'boolean' || column.type === 'date' || column.type === 'datetime') {
        initialFormValue[column.key] = null;
      } else if (column.type === 'multiselect') {
        initialFormValue[column.key] = [];
      } else {
        initialFormValue[column.key] = '';
      }
    }
  });
  
  const dispatch = useAppDispatch();
  const collection = useAppSelector((state) => state.collection.collections[entity.key])
  const submitting = useAppSelector((state) => state.collection.collections[entity.key].status === 'submitting');
  const navigate = useNavigate();
  const location = useLocation();
  const [formValue, setFormValue] = useState(initialFormValue);
  const [formDirty, setFormDirty] = useState(false);
  const changeFormValue = (e: ChangeFieldEventType | null): void => {
    setFormDirty(true);
    setFormValue((state) => {
      if (!e) return state;

      return {
        ...state,
        [e!.target.name]: e!.target.value,
      }
    });
  };

  const onSubmit = async (e?: SyntheticEvent) => {
    if (e) e.preventDefault();

    const payload = {
      key: entity.key,
      parentId,
      parentKey: entity.parent,
      ...prepareFormValue(formValue),
    };

    const result = await dispatch(createCollectionItemAction(payload));

    if (result.type === '@@collection/create/fulfilled') {
      const pathArr = location.pathname.split('/');
      pathArr[pathArr.length - 1] = entity.key + '-' + (result.payload as CollectionItemType).id;

      navigate(pathArr.join('/'));
    }
  }

  const setError = (fieldName: string) => {
    return collection.errors && collection.errors[fieldName] && errorTranslate(collection.errors[fieldName][0]);
  }

  return (
    <CardDataBlock
      title={'Добавить ' + entity.label.genitive.toLowerCase()}
      extra={<ButtonBackBlock />}
    >
      <div className="row justify-content-lg-center">
        <div className="col col-lg-6">
          <div className={styles['entity-create']}>
            <form onSubmit={(e: SyntheticEvent) => onSubmit(e)}>
              <>
                {entity.columns.map((column) => {
                  const invisibleField = !(column.display.create && checkVisibleField(column, formValue));
                
                  if (invisibleField) {
                    return null;
                  }

                  if (column.type === 'boolean') {
                    return (
                      <FieldBooleanBlock
                        key={column.key}
                        name={column.key}
                        label={column.label}
                        value={formValue[column.key]}
                        onChange={changeFormValue}
                        error={setError(column.key)}
                        counted={column.counted}
                        required={column.required}
                        prompt={column.prompt}
                      />
                    );
                  } else if (column.type === 'date') {
                    return (
                      <FieldDateBlock
                        key={column.key}
                        name={column.key}
                        label={column.label}
                        value={formValue[column.key]}
                        onChange={changeFormValue}
                        error={setError(column.key)}
                        counted={column.counted}
                        required={column.required}
                        prompt={column.prompt}
                      />
                    );
                  } else if (column.type === 'datetime') {
                    return (
                      <FieldDatetimeBlock
                        key={column.key}
                        name={column.key}
                        label={column.label}
                        value={formValue[column.key]}
                        onChange={changeFormValue}
                        error={setError(column.key)}
                        counted={column.counted}
                        required={column.required}
                        prompt={column.prompt}
                      />
                    );
                  } else if (column.type === 'multiselect') {
                    return (
                      <FieldMultiselectBlock
                        key={column.key}
                        name={column.key}
                        label={column.label}
                        value={formValue[column.key]}
                        onChange={changeFormValue}
                        error={setError(column.key)}
                        items={column.variants!.map((item) => ({ value: item, label: item }))}
                        counted={column.counted}
                        required={column.required}
                        prompt={column.prompt}
                      />
                    );
                  } else if (column.type === 'select') {
                    return (
                      <FieldSelectBlock
                        key={column.key}
                        name={column.key}
                        label={column.label}
                        value={formValue[column.key]}
                        onChange={changeFormValue}
                        error={setError(column.key)}
                        items={column.variants!.map((item) => ({ value: item, label: item }))}
                        counted={column.counted}
                        required={column.required}
                        prompt={column.prompt}
                      />
                    );
                  } else if (column.type === 'number') {
                    return (
                      <FieldNumberBlock
                        key={column.key}
                        name={column.key}
                        label={column.label}
                        value={formValue[column.key]}
                        onChange={changeFormValue}
                        error={setError(column.key)}
                        counted={column.counted}
                        required={column.required}
                        prompt={column.prompt}
                      />
                    );
                  } else if (column.type === 'decimal') {
                    return (
                      <FieldDecimalBlock
                        key={column.key}
                        name={column.key}
                        label={column.label}
                        value={formValue[column.key]}
                        onChange={changeFormValue}
                        error={setError(column.key)}
                        counted={column.counted}
                        required={column.required}
                        prompt={column.prompt}
                      />
                    );
                  } else if (column.type === 'phone') {
                    return (
                      <FieldPhoneBlock
                        key={column.key}
                        name={column.key}
                        label={column.label}
                        value={formValue[column.key]}
                        onChange={changeFormValue}
                        error={setError(column.key)}
                        counted={column.counted}
                        required={column.required}
                        prompt={column.prompt}
                      />
                    );
                  } else if (column.type === 'long-text') {
                    return (
                      <FieldLongTextBlock
                        key={column.key}
                        name={column.key}
                        label={column.label}
                        value={formValue[column.key]}
                        onChange={changeFormValue}
                        error={setError(column.key)}
                        counted={column.counted}
                        required={column.required}
                        prompt={column.prompt}
                      />
                    );
                  } else if (column.type === 'snils') {
                    return (
                      <FieldSnilsBlock
                        key={column.key}
                        name={column.key}
                        label={column.label}
                        value={formValue[column.key]}
                        onChange={changeFormValue}
                        error={setError(column.key)}
                        counted={column.counted}
                        required={column.required}
                        prompt={column.prompt}
                      />
                    );
                  } else if (column.type === 'passport') {
                    return (
                      <FieldPassportBlock
                        key={column.key}
                        name={column.key}
                        label={column.label}
                        value={formValue[column.key]}
                        onChange={changeFormValue}
                        error={setError(column.key)}
                        counted={column.counted}
                        required={column.required}
                        prompt={column.prompt}
                      />
                    );
                  } else {
                    return (
                      <FieldTextBlock
                        key={column.key}
                        name={column.key}
                        label={column.label}
                        value={formValue[column.key]}
                        onChange={changeFormValue}
                        error={setError(column.key)}
                        counted={column.counted}
                        required={column.required}
                        prompt={column.prompt}
                      />
                    );
                  }
                })}

                <ButtonSaveBlock
                  loading={submitting}
                  onClick={onSubmit}
                  disabled={!formDirty}
                />
                <ButtonBackBlock />
              </>
            </form>
          </div>
        </div>
      </div>
    </CardDataBlock>
  );
}

export { EntityCreateFeature };
