export const spisokJalobDict = [
  'Нет',
  'Боли в области сердца',
  'Боли в суставах',
  'Быстрая утомляемость',
  'Головные боли',
  'Ломкость ногтей',
  'Общая слабость',
  'Снижение концентрации и внимания',
  'Ухудшение зрения',
  'Ухудшение состояния зубов',
  'Повышенная чувствительность зубов',
  'Изменение цвета эмали',
  'Чрезмерное выпадение волос',
];
