export const errorTranslate = (error: string) => {
  if (error.includes('should not be empty')) {
    return 'Поле обязательно для заполнения'
  }

  if (error.includes('must be an email')) {
    return 'Укажите корректную электронную почту'
  }

  if (error.includes('incorrect username or password')) {
    return 'Не корректные имя пользователя или пароль'
  }

  if (error.includes('not valid phone')) {
    return 'Укажите коррректный телефон'
  }

  if (error.includes('not valid passport')) {
    return 'Укажите коррректные серию и номер паспорта'
  }

  return error;
};
