import { EntityType } from '@type/config/entity.type';
import { regionsDict } from './dict/regions.dict';

export const cardEntity: EntityType = {
  key: 'card',
  label: {
    singular: 'Карта',
    plural: 'Карты',
    genitive: 'Карту',
  },
  type: 'list',
  parent: 'root',
  columns: [
    {
      label:'Фамилия',
      key: 'familiya',
      type: 'text',
      display: {
        list: true,
        link: false,
        create: true,
        update: true,
        one: true,
      },
      required: true,
      search: true,
    },
    {
      label:'Имя',
      key: 'imya',
      type: 'text',
      display: {
        list: true,
        link: false,
        create: true,
        update: true,
        one: true,
      },
      search: true,
      required: true,
    },
    {
      label:'Отчество',
      key: 'otchestvo',
      type: 'text',
      display: {
        list: true,
        link: false,
        create: true,
        update: true,
        one: true,
      },
      search: true,
      counted: true,
    },
    {
      label: 'Пол',
      key: 'pol',
      type: 'select',
      display: {
        link: false,
        list: true,
        one: true,
        create: true,
        update: true,
      },
      variants: ['Мужской', 'Женский'],
      required: true,
    },
    {
      label:'Дата рождения',
      key: 'data-rozhdeniya',
      type: 'date',
      display: {
        list: true,
        link: false,
        create: true,
        update: true,
        one: true,
      },
      required: true,
    },
    {
      label:'Снилс',
      key: 'snils',
      type: 'snils',
      display: {
        list: true,
        link: false,
        create: true,
        update: true,
        one: true,
      },
      counted: true,
    },
    {
      label:'Паспорт',
      key: 'passport',
      type: 'passport',
      display: {
        list: true,
        link: false,
        create: true,
        update: true,
        one: true,
      },
      required: true,
    },
    {
      label:'Адрес проживания',
      key: 'adres-prozhivaniya',
      type: 'long-text',
      display: {
        list: true,
        link: false,
        create: true,
        update: true,
        one: true,
      },
    },
    {
      label:'Домашний регион',
      key: 'domashnij-region',
      type: 'select',
      display: {
        list: true,
        link: false,
        create: true,
        update: true,
        one: true,
      },
      variants: regionsDict,
    },
    {
      label:'Номер телефона',
      key: 'nomer-telefona',
      type: 'phone',
      display: {
        list: true,
        link: false,
        create: true,
        update: true,
        one: true,
      },
      required: true,
    },
    {
      label:'Электронная почта',
      key: 'ehlektronnaya-pochta',
      type: 'text',
      display: {
        list: true,
        link: false,
        create: true,
        update: true,
        one: true,
      },
    },
  ]
}