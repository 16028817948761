import { useState } from 'react';
import moment from 'moment';

import { TextField } from '@mui/material';
import { DesktopDatePicker } from '@mui/x-date-pickers';

import { FormFieldBlock } from '@blocks/form-field';
import { AsteriskBlock } from '@blocks/asterisk';

import { FieldDateProps } from './field-date.props';


const FieldDateBlock = ({
  value,
  onChange,
  name,
  error,
  label,
  required,
  counted,
  prompt,
}: FieldDateProps) => {
  const [validDate, setValidDate] = useState(true);
  const [fieldValue, setFielValue] = useState(moment(value));

  const fieldChange = (e: any | null) => {
    if (e) {
      setValidDate(e._isValid)
      setFielValue(e);
      onChange({ target: { name, value: e._d } });
    } else {
      setValidDate(true);
    }
  }

  return (
    <FormFieldBlock error={error} prompt={prompt}>
      <DesktopDatePicker
        label={<>{label}<AsteriskBlock required={required} counted={counted} /></>}
        inputFormat="DD.MM.YYYY"
        value={fieldValue}
        onChange={fieldChange}
        renderInput={(params) => <TextField fullWidth variant="filled" {...params} error={!validDate} />}
      />
    </FormFieldBlock>
  );
};

export { FieldDateBlock };
