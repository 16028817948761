import { EntityType } from '@type/config/entity.type';


export const laboratornyePokazateliPervichnyeEntity: EntityType = {
  key: 'laboratornye_pokazateli_pervichnye',
  label: {
    singular: 'Лабораторные показатели первичный',
    plural: 'Лабораторные показатели первичный',
    genitive: 'Лабораторные показатели первичный',
  },
  type: 'one',
  parent: 'card',
  columns: [
    {
      label: 'Дата забора крови',
      key: 'data-zabora-krovi',
      type: 'date',
      display: {
        list: true,
        link: false,
        create: true,
        update: true,
        one: true,
      },
      required: true,
    },
    {
      label: 'B12 крови, пг/мл',
      key: 'b12-krovi-pg-ml',
      type: 'decimal',
      display: {
        list: true,
        link: false,
        create: true,
        update: true,
        one: true,
      },
      counted: true,
    },
    {
      label: 'Вит С крови, мкг/мл',
      key: 'vit-s-krovi-mkg-ml',
      type: 'decimal',
      display: {
        list: true,
        link: false,
        create: true,
        update: true,
        one: true,
      },
      counted: true,
    },
    {
      label: 'Кортизол крови, ммоль/л',
      key: 'kortizol-krovi-mmol-l',
      type: 'decimal',
      display: {
        list: true,
        link: false,
        create: true,
        update: true,
        one: true,
      },
      counted: true,
    },
    {
      label: 'Железо сыворотки, мкмоль/л',
      key: 'zhelezo-syvorotki-mkmol-l',
      type: 'decimal',
      display: {
        list: true,
        link: false,
        create: true,
        update: true,
        one: true,
      },
      counted: true,
    },
    {
      label: 'Кальций, ммоль/л',
      key: 'kalcij-mmol-l',
      type: 'decimal',
      display: {
        list: true,
        link: false,
        create: true,
        update: true,
        one: true,
      },
      counted: true,
    },
    {
      label: 'Комментарий',
      key: 'kommentarij',
      type: 'long-text',
      display: {
        list: true,
        link: false,
        create: true,
        update: true,
        one: true,
      },
    },
  ]
}
